import React, { useContext, useEffect } from 'react'
import './styles.css'
import ContrastIcon from '@mui/icons-material/Contrast';
import GVIconButton from '../../icon-button';
import { ThemeContext } from '../../../context/theme-context';
import VwButton from '../../vw-button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';
import { env } from '../../../env';
import { useHistory, useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleHalfStroke, faGrip, faSignLanguage, faCookieBite } from '@fortawesome/free-solid-svg-icons'
import { useVwContext } from '../../../context/vw-context';

const Header = (props) => {
  let history = useHistory();
  let { lang } = useParams();

  const { toggleVwClass, isVwActive } = useVwContext();
  const { toggleTheme, theme } = useContext(ThemeContext);

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenCookieBar = () => {
    console.log('changeCookieBar');
    props.changeCookieBar();
  }

  const alterEmail = () => {
    handleClose()
    history.push(`/${lang}/alterar-email-v2`);
  }

  const desativarAutenticacao = () => {
    handleClose()
    history.push(`/${lang}/desativar-2fa-v2`);
  }

  useEffect(() => {
    toggleVwClass()
  }, [])

  return (<header className={"br-header compact" + (theme === 'dark' ? " dark-mode" : "")}>
      <div className="container-lg">
        <div className="header-top">
          <div className="header-logo">
            <a href="https://www.gov.br/pt-br" className="portal-logo" target='blank' >
              <img
                src="https://www.gov.br/++theme++padrao_govbr/img/govbr-logo-large.png" 
                alt="Acesse o portal gov.br"/>
            </a>
              <span className="br-divider vertical"></span>
            <div className="header-sign">
              Ministério da Gestão e da<br/>Inovação em Serviços Públicos
            </div>
          </div>
          <div className="header-actions">
            <div className="header-links dropdown">
              <div className="br-list">
                <div className="header">
                  <div className="title">Acesso Rápido</div>
                </div>
                <Link className="br-item" to={`/${lang}/alterar-email-v2`}>Alterar e-mail na conta gov.br</Link >
                <Link  className="br-item" to={`/${lang}/desativar-2fa-v2`}>Desativar verificação em duas etapas</Link >
              </div>
            </div>
            <div className="header-functions">
              <div className="br-list expand">
                <div className="header">
                  <div className="title">Funcionalidades do Sistema</div>
                </div>
                <div className="br-item">
                  <GVIconButton onClick={handleOpenCookieBar}  type="button" aria-label="Tema">
                    <svg aria-labelledby="svg-inline--fa-title-yVpJloqE9nyM" data-prefix="fas" data-icon="cookie-bite" class="svg-inline--fa fa-cookie-bite " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-label="Gerenciar cookies" alt="Gerenciar cookies" style={ theme === 'light' ? { color: 'var(--interactive)' } : { color: 'var(--interactive-dark)' }}>
                      <title id="svg-inline--fa-title-yVpJloqE9nyM">Gerenciar cookies</title>
                      <descr id="svg-inline--fa-descr-L13gjly8OQPm">Gerenciar cookies</descr>
                      <path fill="currentColor" d="M257.5 27.6c-.8-5.4-4.9-9.8-10.3-10.6c-22.1-3.1-44.6 .9-64.4 11.4l-74 39.5C89.1 78.4 73.2 94.9 63.4 115L26.7 190.6c-9.8 20.1-13 42.9-9.1 64.9l14.5 82.8c3.9 22.1 14.6 42.3 30.7 57.9l60.3 58.4c16.1 15.6 36.6 25.6 58.7 28.7l83 11.7c22.1 3.1 44.6-.9 64.4-11.4l74-39.5c19.7-10.5 35.6-27 45.4-47.2l36.7-75.5c9.8-20.1 13-42.9 9.1-64.9c-.9-5.3-5.3-9.3-10.6-10.1c-51.5-8.2-92.8-47.1-104.5-97.4c-1.8-7.6-8-13.4-15.7-14.6c-54.6-8.7-97.7-52-106.2-106.8zM208 144a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM144 336a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm224-64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z">
                      </path>
                    </svg>
                  </GVIconButton>
                </div>
                <div className="br-item">
                  <GVIconButton onClick={toggleTheme}  type="button" aria-label="Tema">
                    {/*<FontAwesomeIcon role="img" descr="Modo de alto contraste" title="Modo de alto contraste" aria-label="Modo de alto contraste" alt="Modo de alto contraste" icon={faCircleHalfStroke} style={ theme === 'light' ? { color: 'var(--interactive)' } : { color: 'var(--interactive-dark)' }} />*/}
                    <svg aria-labelledby="svg-inline--fa-title-L13gjly8OQPm" data-prefix="fas" data-icon="circle-half-stroke" class="svg-inline--fa fa-circle-half-stroke " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-describedby="Modo de alto contraste" aria-label="Modo de alto contraste" alt="Modo de alto contraste" style={ theme === 'light' ? { color: 'var(--interactive)' } : { color: 'var(--interactive-dark)' }}>
                      <title id="svg-inline--fa-title-L13gjly8OQPm">Modo de alto contraste</title>
                      <descr id="svg-inline--fa-descr-L13gjly8OQPm">Modo de alto contraste</descr>
                      <path fill="currentColor" d="M448 256c0-106-86-192-192-192l0 384c106 0 192-86 192-192zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"></path>
                    </svg>
                  </GVIconButton>
                </div>
                <div className="br-item">
                  <VwButton btnStyle={ theme === 'light' ? { color: 'var(--interactive)' } : { color: 'var(--interactive-dark)' }}  type="button" aria-label="Acessibilidade"/>
                </div>
              </div>
              <div className="br-list retract">
                <div className="header">
                  <div className="title">Funcionalidades do Sistema</div>
                </div>
                <div className="br-item">
                  <GVIconButton
                    id="fast-access-button"
                    className="circle small"
                    aria-label="Abrir Acesso Rápido"
                    type="button"
                    data-toggle="dropdown"
                    aria-controls={open ? 'fast-access-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <FontAwesomeIcon icon={faGrip} style={ theme === 'light' ? { color: 'var(--interactive)' } : { color: 'var(--interactive-dark)' }} />
                  </GVIconButton>
                  <Menu
                    id="fast-access-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'fast-access-button',
                    }} >
                    <MenuItem onClick={toggleTheme}><FontAwesomeIcon icon={faCircleHalfStroke} style={ theme === 'light' ? { color: 'var(--black)' } : { color: 'var(--interactive-dark)' }} />&nbsp;&nbsp;&nbsp;Contraste</MenuItem>
                    <MenuItem onClick={toggleVwClass}  disabled={isVwActive} aria-label="Acessibilidade"><FontAwesomeIcon icon={faSignLanguage} style={ theme === 'light' ? { color: 'var(--black)' } : { color: 'var(--interactive-dark)' }}  type="button" />&nbsp;&nbsp;&nbsp;VLibras</MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          <div className="header-menu">
            <div className="header-info">
              <div className="header-title">Atendimento gov.br</div>
              <div className="header-subtitle">Ministério da Gestão e da Inovação em Serviços Públicos</div>
            </div>
          </div>
        </div>
      </div>
    </header>)
}

export default Header