import React, { createContext, useContext, useEffect, useState } from 'react';

const VwContext = createContext();

export const useVwContext = () => useContext(VwContext);

export const VwProvider = ({ children }) => {
  const [isVwActive, setIsVwActive] = useState(false);

  const toggleVwClass = () => {
    const vwElement = document.querySelector('[vw]');
    if (vwElement) {
      const isDisabled = vwElement?.classList.contains('disabled');
      if(isDisabled) {
        vwElement.classList.toggle('enabled');
        vwElement.classList.toggle('disabled');
      }
    }
  };

  const handleToggleVwClass = (enabled) => {
    const vwElement = document.querySelector('[vw]');
    if (vwElement) {
      if (enabled != null && enabled) {
        const isDisabled = vwElement?.classList.contains('disabled');
        if (isDisabled) {
          vwElement.classList.toggle('enabled');
          vwElement.classList.toggle('disabled');
        }
      } else {
        const isEnabled = vwElement?.classList.contains('enabled');
        if (isEnabled) {
          vwElement.classList.toggle('enabled');
          vwElement.classList.toggle('disabled');
        }
      }
    }
  };

  useEffect(() => {
    const handleVwPluginWrapperClassChange = () => {
      const vwPluginWrapper = document.querySelector('[vw-plugin-wrapper]');
      const isActive = vwPluginWrapper?.classList.contains('active');
      setIsVwActive(isActive);
    };

    document.addEventListener('click', handleVwPluginWrapperClassChange);

    return () => {
      document.removeEventListener('click', handleVwPluginWrapperClassChange);
    };
  }, []);

  const contextValue = {
    toggleVwClass,
    handleToggleVwClass,
    isVwActive
  };

  return (
    <VwContext.Provider value={contextValue}>
      {children}
    </VwContext.Provider>
  );
};