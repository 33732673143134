import React from 'react'
import '../../../../App.css'
import '../../../../styles/spacing/spacing.css'
import '../../../../styles/fonts/font-size.css'
import Step, { stepClasses } from '@mui/material/Step';
import Stepper, { stepperClasses } from '@mui/material/Stepper';
import StepButton, { stepButtonClasses } from '@mui/material/StepButton';
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector'
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel'
import { makeStyles, styled } from '@mui/styles';
import PropTypes from "prop-types"
import CheckIcon from '@mui/icons-material/Check';

const { ...darkPaletteStyleTokens } = {
  '--color': 'var(--color-dark)',
  '--color-rgb': 'var(--color-dark-rgb)',
  '--text-color': 'var(--color-dark)',
  '--interactive': 'var(--interactive-dark)',
  '--interactive-rgb': 'var(--interactive-dark-rgb)',
  '--visited': 'var(--visited-dark)',
  '--hover': 'var(--hover-dark)',
  '--pressed': 'var(--pressed-dark)',
  '--focus-color': 'var(--focus-color-dark)',
  '--focus': 'var(--focus-color-dark)',
}

export const GVBRStepper = styled(Stepper)(({ theme }) => ({
  [`&.${stepperClasses.root}`]: {
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'var(--background-alternative)',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
    gridTemplateRows: 'none',
    minHeight: '130px',
    overflowX: 'hidden',
    overflowY: 'hidden',
    paddingTop: 0,
    width: '100%',
    '@media (max-width:991px)': {
      maxHeight: '130px',
      minHeight: '130px',
      overflowY: 'hidden',
      paddingTop: 0,
      position: 'relative',
      transitionDelay: '0s',
      transitionDuration: '0.25s',
      transitionProperty: 'all',
      transitionTimingFunction: 'linear',
      '&::after': {
        backgroundColor: 'transparent',
        bottom: '2px',
        color: 'var(--interactive)',
        content: '"\f7a4"',
        display: 'block',
        fontFamily: '"Font Awesome 5 Free", sans-serif',
        fontSize: 'var(--switch-icon-size)',
        fontWeight: 'var(--font-weight-black)',
        height: '1em',
        left: '50%',
        marginLeft: '-0.5em',
        position: 'absolute',
        top: 'unset',
        transitionDelay: '0s',
        transitionDuration: '0.25',
        transitionProperty: 'all',
        transitionTimingFunction: 'linear',
        width: 'auto'
      }
    }
  },
  '@media (max-width:991px)': {
    maxHeight: '100px',
    minHeight: '100px',
    overflowY: 'hidden',
    paddingTop: 0,
    position: 'relative',
    transitionDelay: '0s',
    transitionDuration: '0.25s',
    transitionProperty: 'all',
    transitionTimingFunction: 'linear',
    '.MuiStepper-root::after': {
      backgroundColor: 'transparent',
      bottom: '2px',
      color: 'var(--interactive)',
      content: '"\f7a4"',
      display: 'block',
      fontFamily: '"Font Awesome 5 Free", sans-serif',
      fontSize: 'var(--switch-icon-size)',
      fontWeight: 'var(--font-weight-black)',
      height: '1em',
      left: '50%',
      marginLeft: '-0.5em',
      position: 'absolute',
      top: 'unset',
      transitionDelay: '0s',
      transitionDuration: '0.25',
      transitionProperty: 'all',
      transitionTimingFunction: 'linear',
      width: 'auto'
    }
  },
  ...(theme.palette.mode === 'dark' && {
    ...darkPaletteStyleTokens,
    backgroundColor: 'transparent'
  })
}));

export const GVBRStep = styled(Step)(({ theme }) => ({
  [`&.${stepClasses.root}`]: {
    top: '40%'
  },
  ...(theme.palette.mode === 'dark' && { ...darkPaletteStyleTokens })
}));

export const GVBRStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    top: '-50%',
    transitionDelay: '0s',
    transitionDuration: '0.15s',
    transitionProperty: 'all',
    transitionTimingFunction: 'linear',
    zIndex: 1,
    '@media (max-width:670px)': {
      top: '-40%',
    },
    '@media (max-width:600px)': {
      top: '-30%',
    },
    '@media (max-width:500px)': {
      top: '-20%',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: 'var(--interactive)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'var(--interactive)',
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'var(--interactive)',
    },
  },
  ...(theme.palette.mode === 'dark' && { ...darkPaletteStyleTokens })
}));

export const GVBRStepButton = styled(StepButton)(({ theme }) => ({
  [`&.${stepButtonClasses.root}`]: {
    backgroundColor: 'transparent',
    border: '0',
    boxShadow: 'none',
    color: 'var(--interactive)',
    fontSize: 'var(--font-size-scale-up-01)',
    lineHeight: '19px',
    maxHeight: '90px',
    minHeight: '40px',
    alignItems: 'self-start',
    paddingBottom: '0',
    paddingTop: 'var(--spacing-scale-2x)',
    position: 'relative',
    textIndent: '0',
    transitionDelay: '0s',
    transitionDuration: '0.15s',
    transitionProperty: 'all',
    transitionTimingFunction: 'linear',
    '@media (max-width: 991px)': {
      paddingTop: '25%',
    }
  },
  // [`&.Mui-disabled span span div`]: {
  //   color: 'rgba(var(--interactive-rgb), var(--disabled))',
  //   border: '2px solid rgba(var(--interactive-rgb), var(--disabled))',
  // },
  // [`&.Mui-disabled span span span`]: {
  //   color: 'rgba(var(--interactive-rgb), var(--disabled))',
  // },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
  ...(theme.palette.mode === 'dark' && { ...darkPaletteStyleTokens }),
  '&:focus-visible .MuiStepLabel-root .MuiStepLabel-iconContainer > div': {
    outlineColor: '#C2850C',
    outlineOffset: '4px',
    outlineStyle: 'dashed',
    outlineWidth: '4px'
  }
}));

export const GVBRStepLabel = styled(StepLabel)(({ theme, ownerstate }) => ({
  [`& .${stepLabelClasses.label}`]: {
    color: 'var(--interactive)',
    fontSize: '16.8px',
    lineHeight: '19px',
    textIndent: '0',
    '@media (max-width:991px)': {
      fontSize: '12px',
      top: '30%',
      lineHeight: '12px',
      maxWidth: '80px',
    },
    '@media (max-width:550px)': {
      fontSize: '9px',
      top: '45%',
      lineHeight: '9px'
    },
    [`&.${stepLabelClasses.active}`]: {
      color: 'var(--active)',
      fontWeight: '600'
    }
  },
  ...(theme.palette.mode === 'dark' && { ...darkPaletteStyleTokens })
}))

export const StepperPannelFooter = styled('div')(({ theme }) => ({
  alignSelf: 'flex-end',
  backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'var(--background-alternative)',
  borderTop: '1px solid var(--border-color)',
  height: 'fit-content',
  marginTop: '1px',
  padding: 'var(--spacing-scale-2x) 1.5%',
  width: '100%',
  '@media (max-width:574px)': {
    padding: '4% !important',
    position: 'fixed',
    left: '0px', 
    top: '90%',
    zIndex: '9'
  },
  ...(theme.palette.mode === 'dark' && { ...darkPaletteStyleTokens })
}))

const GVBRStepIconRoot = styled('div')(({ theme, ownerstate }) => ({
  backgroundColor: 'var(--background)',
  fill: 'var(--background)',
  border: '2px solid var(--interactive)',
  borderRadius: '50%',
  boxSizing: 'border-box',
  color: 'var(--interactive)',
  content: 'attr(step) !important',
  display: 'block',
  fontSize: '20.16px',
  fontWeight: '600',
  height: '36px',
  left: '50%',
  lineHeight: '29px',
  marginTop: '-13px',
  position: 'absolute',
  top: 0,
  transform: 'translateX(-50%)',
  transition: 'all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s',
  width: '36px',
  zIndex: 3,
  ...(ownerstate.active && {
    backgroundColor: 'var(--active)',
    fill: 'var(--active)',
    border: '2px solid var(--background-light)',
    color: 'var(--background-light)',
    fontWeight: '700',
  }),
  ...(theme.palette.mode === 'dark' && { ...darkPaletteStyleTokens })
}));

const GVBRStepIconCompleted = styled('div')(({ theme, ownerstate }) => ({
  backgroundColor: 'var(--success)',
  borderRadius: '50%',
  position: 'absolute',
  width: '20px',
  height: '20px',
  top: '-10px',
  right: '-8px',
  display: 'none',
  ...(ownerstate.completed && {
    display: 'block'
  }),
  ...(theme.palette.mode === 'dark' && { ...darkPaletteStyleTokens })
}));

export function GVBRStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3,
  };

  return (
    <GVBRStepIconRoot ownerstate={{ completed, active }} className={className}>
      {props.icon}
      <GVBRStepIconCompleted ownerstate={{ completed, active }}>
        <CheckIcon style={{ fill: 'var(--pure-0)', fontSize: '0.9rem', marginBottom: '5px' }} />
      </GVBRStepIconCompleted>
    </GVBRStepIconRoot>
  );
}

GVBRStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export const wizardStyles = makeStyles((theme) => ({
  root: {
    '--wizard-min-height': '300px',
    '--wizard-max-height': '800px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 'var(--spacing-scale-4x)',
    maxWidth: 'none',
  }
}));

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'var(--background-alternative)',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
    gridTemplateRows: 'none',
    minHeight: '164px',
    overflowX: 'auto',
    overflowY: 'hidden',
    paddingTop: 'var(--spacing-scale-7x)',
    width: '100%',
    '@media (max-width:991px)': {
      maxHeight: '100px',
      minHeight: '100px',
      overflowY: 'hidden',
      paddingTop: 'var(--spacing-scale-3x)',
      position: 'relative',
      transitionDelay: '0s',
      transitionDuration: '0.25s',
      transitionProperty: 'all',
      transitionTimingFunction: 'linear'
    }
  },
  '& .MuiStepper-root::after': {
    '@media (max-width:991px)': {
      backgroundColor: 'transparent',
      bottom: '2px',
      color: 'var(--interactive)',
      content: '"\f7a4"',
      display: 'block',
      fontFamily: '"Font Awesome 5 Free", sans-serif',
      fontSize: 'var(--switch-icon-size)',
      fontWeight: 'var(--font-weight-black)',
      height: '1em',
      left: '50%',
      marginLeft: '-0.5em',
      position: 'absolute',
      top: 'unset',
      transitionDelay: '0s',
      transitionDuration: '0.25',
      transitionProperty: 'all',
      transitionTimingFunction: 'linear',
      width: 'auto'
    }
  },
  '& .MuiStepButtonRoot': {
    backgroundColor: 'transparent',
    border: '0',
    boxShadow: 'none',
    color: 'var(--interactive)',
    fontSize: 'var(--font-size-scale-up-01, 16.8px)',
    lineHeight: '19px',
    maxHeight: '90px',
    paddingBottom: '0',
    paddingTop: 'var(--spacing-scale-2x)',
    position: 'relative',
    textIndent: '0',
    transitionDelay: '0s',
    transitionDuration: '0.15s',
    transitionProperty: 'all',
    transitionTimingFunction: 'linear',
  },
  '& .MuiStepButtonRoot .MuiStepLabel-root.Mui-disabled': {
    color: 'rgba(var(-interactive-rgb), var(--disabled))',
    opacity: 1
  },
  '& .MuiStepButtonRoot .MuiStepLabel-root.Mui-disabled .MuiStepLabel-iconContainer .MuiStepIcon-root': {
    opacity: 'var(--disabled)'
  },
  '& .MuiStepButtonRoot:focus': {
    outline: 'none'
  },
  '& .MuiStepButtonRoot.focus-visible:not(.MuiStepLabel-root.Mui-disabled):not(.MuiStepLabel-root.Mui-disabled)::before, .MuiStepButtonRoot:focus-visible:not(.MuiStepLabel-root.Mui-disabled):not(.MuiStepLabel-root.Mui-disabled)::before': {
    outlineColor: 'var(--focus-color)',
    outlineOffset: 'var(--focus-offset)',
    outlineStyle: 'var(--focus-style)',
    outlineWidth: 'var(--focus-width)'
  },
  '& .MuiStepButtonRoot:hover:not(.MuiStepLabel-root.Mui-disabled)::before': {
    backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))'
  },
  '& .MuiStepButtonRoot:active:not(.MuiStepLabel-root.Mui-disabled)::before': {
    backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--pressed)), rgba(var(--interactive-rgb), var(--pressed)))'
  },
  '& .MuiStepButtonRoot::before': {
    backgroundColor: 'var(--background)',
    border: '2px solid var(--interactive)',
    borderRadius: '50%',
    boxSizing: 'border-box',
    color: 'var(--interactive)',
    content: 'attr(step) !important',
    display: 'block',
    fontSize: 'var(--font-size-scale-up-02, 20.16px)',
    fontWeight: 'var(--font-weight-semi-bold, 600)',
    height: '36px',
    left: '50%',
    lineHeight: '29px',
    marginTop: '-13px',
    position: 'absolute',
    top: '0',
    transform: 'translateX(-50%)',
    transition: 'all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s',
    width: '36px',
    zIndex: '3',
  },
  '& .MuiStepButtonRoot::after': {
    backgroundColor: 'var(--interactive) !important',
    content: '""',
    display: 'block',
    height: '1px',
    left: 'calc(-50% + 17px)',
    position: 'absolute',
    top: '5px',
    transitionDelay: '0s',
    transitionDuration: '0.15s',
    transitionProperty: 'all',
    transitionTimingFunction: 'linear',
    width: 'calc(100% - 34px)',
    zIndex: '1',
  },
  '& .MuiStepButtonRoot:first-child::after': {
    display: 'none'
  },
  '& .MuiStepButtonRoot .MuiStepLabel-root .MuiStepLabel-iconContainer.Mui-active': {
    color: 'var(--active)',
    fontWeight: 'var(--font-weight-semi-bold, 600)'
  },
  '& .MuiStepButtonRoot .MuiStepLabel-root .MuiStepLabel-iconContainer.Mui-active::after': {
    backgroundColor: 'var(--active)'
  },
  '& .MuiStepButtonRoot .MuiStepLabel-root .MuiStepLabel-iconContainer.Mui-active::before': {
    backgroundColor: 'var(--active)',
    border: '2px solid var(--background-light)',
    color: 'var(--background-light)',
    fontWeight: 'var(--font-weight-bold, 700)',
  },
  '& .MuiStepButtonRoot .MuiStepLabel-root .MuiStepLabel-labelContainer.Mui-active': {
    '@media (max-width:991px)': {
      fontSize: 'var(--font-size-scale-down-02, 9.716px)',
      lineHeight: '1em',
      textAlign: 'center',
    }
  }
}));