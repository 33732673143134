import React from 'react'
import GVButton from '../../../../../../components/gov-br/button'
import { connect, useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../../../../../redux/actions/alter-email-step-for-mine'
import GvDialog from '../../../../../../components/gov-br/dialog'
import { useFormContext } from 'react-hook-form'
import { Grid, Typography } from '@mui/material'
import GVFeedback from '../../../../../../components/feedback'
import CustomTextField from '../../../../../../components/textfield'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { confirm, create } from '../../../../../../services/email-confirmation.service'

function ConfirmEmailDialog(props) {
  const methods = useFormContext();
  const formValues = methods.watch()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [dialogStep, setDialogStep] = React.useState(1)

  const [code, setCode] = React.useState('')
  const [validationSend, setValidationSend] = React.useState(false)

  const dispatch = useDispatch();
  const attempts = useSelector((state) => state.alterEmailStepForMine.attemps)
  const lastAttemp = useSelector((state) => state.alterEmailStepForMine.lastAttemp)
  const [canRetry, setCanRetry] = React.useState(true)
  const [timeLeft, setTimeLeft] = React.useState(0)

  React.useEffect(() => {
   if (attempts >= 5) {
      const now = new Date().getTime()
      const timeElapset = now - lastAttemp
      const timeRemaining = 5 * 60 * 1000 - timeElapset
      if (timeRemaining > 0) {
        setCanRetry(false)
        setTimeLeft(timeRemaining);
      }
    }
  }, [attempts, lastAttemp]);

  React.useEffect(() => {
    if (!canRetry && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1000);
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft <= 0) {
      setCanRetry(true);
    }
  }, [canRetry, timeLeft]);

  React.useEffect(() => {
    if (props.alterEmailStepForMine != null && props.alterEmailStepForMine.isOpen != null && props.alterEmailStepForMine.isOpen)
      onOpenDialog()
  }, [props.alterEmailStepForMine.isOpen])

  const formatTime = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const onOpenDialog = () => {
    setDialogStep(1)
    setDialogOpen(true)
  }

  const onCloseDialog = () => {
    props.openDialogAlterEmailStepForMineConfirmEmail(false)
    setDialogOpen(false)
  }

  const handleSendConfirmationCode = async (e) => {
    e.preventDefault()

    props.showLoading(true)
    
    let data = new FormData()
    data.append('email', formValues.email)

    const response = await create(data)
    if (response && response.success) {
      props.showLoading(false)
      if(dialogStep == 1) setDialogStep(2)
      props.addEmailLimitAlterEmailStepForMineConfirmEmail()
    } else {
      if (response && response.errors && Array.isArray(response.errors) && response.errors.length > 0)
        props.showSnackbar("error", response.errors[0])
      else
        props.showSnackbar("error", "Ocorreu um erro ao enviar o código de confirmação!")
      props.showLoading(false)
    }
  }

  const handleConfirmEmail = async (e) => {
    e.preventDefault()
    setValidationSend(true)
    if (code != null && code.length > 0) {
      props.showLoading(true)

      let data = new FormData()
      data.append('email', formValues.email)
      data.append('code', code)

      const response = await confirm(data)
      if (response && response.success) {
        props.showLoading(false)
        onCloseDialog()
        props.resetEmailLimitAlterEmailStepForMineConfirmEmail()
        props.addLastEmailAlterEmailStepForMineConfirmEmail(formValues.email)
        props.completeDialogAlterEmailStepForMineConfirmEmail(true)
      } else {
        if (response && response.errors && Array.isArray(response.errors) && response.errors.length > 0)
          props.showSnackbar("error", response.errors[0])
        else
          props.showSnackbar("error", "Código inválido!")
        props.showLoading(false)
      }
    }
  }

  return (<GvDialog
      open={dialogOpen}
      onClose={onCloseDialog}
      title={"Confirmação de e-mail"}
      content={<Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row' }}>
          <MarkEmailReadIcon />
          <Typography variant="body1" component="p" fontWeight="bold" style={{ paddingLeft: '5px' }} >{formValues.email}</Typography>
        </Grid>
        {dialogStep == 1 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" >Enviaremos um código de verificação para confirmar que você tem acesso ao e-mail informado.</Typography>
        </Grid>}
        {dialogStep == 1 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" >Para avançar, será preciso checar o seu e-mail e informar o código enviado.</Typography>
        </Grid>}
        {dialogStep == 2 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" >Digite o código que acabamos de enviar para o e-mail informado.</Typography>
        </Grid>}
        {dialogStep == 2 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" >Se não o encontrar, lembre-se de verificar também na sua pasta de lixo eletrônico e spam.</Typography>
        </Grid>}
        {dialogStep == 2 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
              style={{
                display: 'flex',
                alignItems: 'center'
              }}>
              <Typography variant="body1" component="span" >Não recebeu um e-mail?</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <GVButton
                variant="text"
                color="inherit"
                onClick={handleSendConfirmationCode}
                label="Enviar novamente"
                disabled={!canRetry} />
            </Grid>
          </Grid>
        </Grid>}
        {(dialogStep == 2 && attempts >= 3 && !canRetry) && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }}>
          <GVFeedback warning>Limite de envios atingido. Aguarde {formatTime(timeLeft)} minutos.</GVFeedback>
        </Grid>}
        {dialogStep == 2 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }}>
        <CustomTextField
          name="code"
          label=""
          placeholder="Insira o código"
          required
          fullWidth
          value={code}
          onChange={(e) => setCode(e.target.value)}
          inputLabelProps={{ shrink: true }}
          error={validationSend && code?.length == 0}
          helperText={"Insira o código para validar o e-mail"}
          inputProps={{ maxLength: 255 }}/>
        </Grid>}
      </Grid>}
      actions={
        <>
          <GVButton
            variant="outlined"
            color="inherit"
            onClick={onCloseDialog}
            sx={{ mr: 1 }}
            label="Cancelar" />
          {dialogStep == 1 ?
            <GVButton 
              onClick={handleSendConfirmationCode}
              variant="contained"
              label="Enviar" /> :
            <GVButton 
              onClick={handleConfirmEmail}
              variant="contained"
              label="Validar código" />}
        </>
      }
      fullWidth={true}
      maxWidth={'md'}
      name={'personal-data-email-confirmation'} />)
}

const mapStateToProps = state => ({
  alterEmailStepForMine: state.alterEmailStepForMine
})

const mapDispatchToProps =
  dispatch => bindActionCreators(componentActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailDialog)