import React, { useEffect } from 'react'
import { Divider, Grid, Typography } from '@mui/material';
import Alert, { alertClasses } from '@mui/material/Alert'
import * as componentActions from '../../../../redux/actions/alter-email-step-for-mine'
import * as documentRecognitionStepComponentActions from '../../../../redux/actions/document-recognition-step'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import GVFeedback from '../../../../components/feedback';
import GVAutocomplete from '../../../../components/gov-br/autocomplete';
import GVAutocompleteTypeDocument from '../../../../components/gov-br/autocomplete-type-document';
import DocumentTypeInfo from './components/document-type-info';
import { useFormContext } from 'react-hook-form';
import GVFileUpload from '../../../../components/gov-br/upload';
import { withStyles } from '@mui/styles'
import { Styles } from '../../../../styles/material-styles'
import GVMessage from '../../../../components/gov-br/message';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DocumentRecognitionDialog from './components/document-recognition-dialog';
import GVButton from '../../../../components/gov-br/button';
import { Mexx2DOConsole } from '../../../../utils/mexxTalkConsole';

const StepDocumentRecognition = React.memo(function StepDocumentRecognition(props) {

  const [selectField, setSelectField] = React.useState(null)
  const [uploadField, setUploadField] = React.useState(null)
  const [attachmentList, setAttachmentList] = React.useState([])
  const [organizationFieldMajor, setOrganizationFieldMajor] = React.useState('')
  const [fileUploadField, setFileUploadField] = React.useState('')
  const [legalResponsible, setLegalResponsible] = React.useState(false)
  const [legalRepresentant, setLegalRepresentant] = React.useState(false)
  const methods = useFormContext();
  const {setValue, unregister  } = methods
  const formValues = methods.watch();
  const [documentSelected, setDocumentSelected] = React.useState({id: 0});
  const [displayDocumentImage, setDisplayDocumentImage] = React.useState(true);
  
  const completed = props.stepper.completed;
  const isCompleted = completed?.[2] === true;

  const documentRecognitionStep = useSelector(state => state.documentRecognitionStep.documentNifStep);
  const stepsData = useSelector((state) => state.calledServiceSettingsStep.steps)
  const stepData = stepsData && stepsData.length > 0 ? stepsData.filter(element => element.step === 5).sort((a, b) => a.order - b.order) : []
  const stepForMinor = stepsData && stepsData.length > 0 ? stepsData.filter(element => element.step === 3).sort((a, b) => a.order - b.order) : []
  const stepForOther = stepsData && stepsData.length > 0 ? stepsData.filter(element => element.step === 4).sort((a, b) => a.order - b.order) : []

  useEffect(() => {
    if (stepData.length > 0) {
      let sField = stepData.find(element => element.fieldtype == 2 && element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype === 4 && element.calledservicesettingsorganizationfield?.label == "Documento")
      if (sField != null) {
        const fieldId = sField.calledservicesettingsorganizationfield?.id
        const options = sField.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues
        const formattedOptions = formatOptions(options)
        sField.calledservicesettingsorganizationfield.organizationfield.organizationfieldoptionsvalues = formattedOptions
        setOrganizationFieldMajor(`ofield-${fieldId}`)
      }
      setSelectField(sField)
      let uField = stepData.find(element => element.fieldtype == 3 && element?.calledservicesettingsfileupload != null && element?.calledservicesettingsfileupload.filelimit == 1)
      if (uField != null) {
        const fieldId = uField.calledservicesettingsfileupload?.id
        setFileUploadField(`files-${fieldId}`)
      }
      setUploadField(uField)
    }
    let issetLegalReponsible = stepForMinor.find(element => element.calledservicesettingsorganizationfield?.label === "Nome do responsável legal")
    if (issetLegalReponsible) {
      const fieldId = issetLegalReponsible.calledservicesettingsorganizationfield?.id
      if (formValues[`ofield-${fieldId}`] && formValues[`ofield-${fieldId}`].length > 0)
        setLegalResponsible(true)
    }
    let issetOtherLegalReponsible = stepForOther.find(element => element.calledservicesettingsorganizationfield?.label === "Nome do representante legal")
    if (issetOtherLegalReponsible) {
      const fieldId = issetOtherLegalReponsible.calledservicesettingsorganizationfield?.id
      if (formValues[`ofield-${fieldId}`] && formValues[`ofield-${fieldId}`].length > 0)
        setLegalRepresentant(true)
    }
  }, [])

  useEffect(() => {
    if (isCompleted && fileUploadField.length > 0 && formValues[fileUploadField] && formValues[fileUploadField].length > 0) {
      if (attachmentList.length !== formValues[fileUploadField].length) {
        setAttachmentList(formValues[fileUploadField]);
      }
    }
  }, [isCompleted, formValues, attachmentList, fileUploadField])

  const formatOptions = (options, orderType = "id") => {
    if (options && options.length > 0)
      options.sort((a, b) => a[orderType] - b[orderType])
    else
      options = []
    return options
  }

  const handleResetDocument = _ => {
    setDocumentSelected({id: 0});
    setDisplayDocumentImage(false);
    props.resetDataDialogDocumentRecognitionStep();
  }

  const changeValues = (e,value) => {
    Mexx2DOConsole.log("e,value")
    Mexx2DOConsole.log(e)
    Mexx2DOConsole.log(value)
    setDisplayDocumentImage(true);
    if(value.name != null && value.name != "Documento digital de identificação" ){
      var x = formValues
      var z = formValues[organizationFieldMajor]
      Mexx2DOConsole.log(x)
      Mexx2DOConsole.log(z)

      for (let key of Object.keys(x)) {
        Mexx2DOConsole.log("x here")
        Mexx2DOConsole.log(key)
        if(key.includes('files-')){
          unregister(key)
        }
      }

      setDocumentSelected(value);
    }
  }

  return (<React.Fragment>
    <Grid container spacing={2}>
      {isCompleted && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVMessage success>Esta etapa já foi concluída. Caso deseje alterar os dados, volte ao início</GVMessage>
      </Grid>}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <DocumentScannerIcon style={{ fontSize: 'var(--font-size-scale-up-02)' }} />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '5px', fontSize: 'var(--font-size-scale-up-02)' }} >Leitura do documento do {(legalResponsible) ? "responsável legal" : legalRepresentant ? "representante legal" : "titular"}</Typography>
      </Grid>
      {!isCompleted && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <Typography variant="body1" component="span" >Agora vamos realizar a leitura do documento. Selecione abaixo aquele que deseja utilizar;</Typography>
      </Grid>}
      {(!isCompleted && selectField) && (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVAutocompleteTypeDocument
          name={`ofield-${selectField.calledservicesettingsorganizationfield?.id}`}
          changeSelect={changeValues}
          label={''}
          options={selectField.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues}
          placeholder="Selecione o tipo de documento a ser utilizado"
          disabled={documentRecognitionStep === 2}
          fullWidth
          required 
          value={documentSelected}
        />
      </Grid>)}
      {(!isCompleted && documentRecognitionStep === 2) && (<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }} >
        <GVButton
          variant="outlined"
          color="inherit"
          sx={{ mr: 1 }}
          onClick={handleResetDocument}
          label="Escolher outro documento" />
      </Grid>)}
      {((isCompleted) && organizationFieldMajor.length > 0 && formValues[organizationFieldMajor] && formValues[organizationFieldMajor].name) && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }}>
        <Typography variant="body1" component="span" >{formValues[organizationFieldMajor].name}</Typography>
      </Grid>}
      {(organizationFieldMajor.length > 0 && formValues[organizationFieldMajor] && formValues[organizationFieldMajor].name !== 'Documento digital de identificação' && formValues[organizationFieldMajor].name !== 'Selecione o tipo de documento a ser utilizado') && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVFeedback warning2>Cópias de documentos rasurados ou rasgados não serão aceitos</GVFeedback>
      </Grid>}
      {(organizationFieldMajor.length > 0 && formValues[organizationFieldMajor] && formValues[organizationFieldMajor].name === 'Documento digital de identificação') && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVFeedback warning2>O documento a ser anexado deve apresentar de forma clara a foto do titular, nome completo, número de CPF e método para verificação de autencidade</GVFeedback>
      </Grid>}
      {(displayDocumentImage && organizationFieldMajor.length > 0 && formValues[organizationFieldMajor] && formValues[organizationFieldMajor].name) && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <DocumentTypeInfo inputName={organizationFieldMajor} />
      </Grid>}
      
      {(!isCompleted && organizationFieldMajor.length > 0 && formValues[organizationFieldMajor] && formValues[organizationFieldMajor].name === 'Documento digital de identificação' && uploadField) && (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVFileUpload
          item={{
            id: uploadField?.calledservicesettingsfileupload?.id,
            description: "",
            label: '',
            filelimit: uploadField?.calledservicesettingsfileupload?.filelimit,
            calledservicesettingsfileuploadattachments: []
          }}
          classes={props.classes}
          classStyle={undefined} />
      </Grid>)}
      {(isCompleted && organizationFieldMajor.length > 0 && formValues[organizationFieldMajor] && formValues[organizationFieldMajor].name && attachmentList.length > 0) && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <AttachFileIcon />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Anexo:</Typography>
      </Grid>}
      {isCompleted && attachmentList.map(attachment => {
        return (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{attachment.name}</Typography>
      </Grid>)}
      )}
    </Grid>
  </React.Fragment>)
})

const mapStateToProps = state => ({
  classes: state.classes,
  stepper: state.stepper,
  documentRecognitionStep: state.documentRecognitionStep,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, componentActions, documentRecognitionStepComponentActions), dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(StepDocumentRecognition));