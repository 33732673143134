import React from 'react'
import '../../../../App.css'
import '../../../../styles/spacing/spacing.css'
import '../../../../styles/fonts/font-size.css'
import { styled } from "@mui/styles"

const { ...darkPaletteStyleTokens } = {
  '--color': 'var(--color-dark)',
  '--color-rgb': 'var(--color-dark-rgb)',
  '--text-color': 'var(--color-dark)',
  '--interactive': 'var(--interactive-dark)',
  '--interactive-rgb': 'var(--interactive-dark-rgb)',
  '--visited': 'var(--visited-dark)',
  '--hover': 'var(--hover-dark)',
  '--pressed': 'var(--pressed-dark)',
  '--focus-color': 'var(--focus-color-dark)',
  '--focus': 'var(--focus-color-dark)',
}

export const StepperPannelContent = styled('div')(({ theme }) => ({
  borderTop: '1px solid var(--border-color)',
  flexGrow: '1',
  height: 'auto',
  maxHeight: 'none',
  overflowX: 'auto',
  overflowY: 'auto',
  padding: 'var(--spacing-scale-base) var(--spacing-scale-2x)',
  '@media (max-width:574px)': {
    minHeight: '101% !important', // Ajuste a altura dinamicamente para telas menores
  },
  ...(theme.palette.mode === 'dark' && { ...darkPaletteStyleTokens })
}))