import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { FormControl, IconButton, InputAdornment } from '@mui/material';
import { styled } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../../../App.css'
import './styles.css'
import GVFeedback from '../../feedback';

const options = [{ id: 1, name:'Option 1' }, { id: 2, name:'Option 2' }];

const GVBRAutocompleteLabel = styled('label')(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'var(--color-dark)' : 'var(--color)',
  fontSize: '0.78rem',
  fontWeight: 'var(--font-weight-semi-bold)',
  lineHeight: 'var(--font-line-height-medium)',
  marginBottom: 'var(--spacing-scale-half)',
  '&:focus-visible': {
    color: theme.palette.mode === 'dark' ? 'var(--color-dark)!important' : 'var(--color)!important',
  }
}))

const GVBRAutocomplete = styled(Autocomplete)(({ theme }) => {
  return ({
    '--input-button-margin': 'var(--spacing-scale-half)',
    '--input-padding': '0 var(--spacing-scale-2x)',
    '--input-padding-button': 'var(--spacing-scale-5x)',
    '--input-padding-icon': 'var(--spacing-scale-5x)',
    '--input-size': 'var(--input-medium)',
    '--input-small': '32px',
    '--input-medium': '40px',
    '--input-large': '48px',
    '--input-highlight': '56px',
    '--color': theme.palette.mode === 'dark' ? 'var(--color-dark)' : 'var(--color-light)',
    '--color-rgb': theme.palette.mode === 'dark' ? 'var(--color-dark-rgb)' : 'var(--color-light-rgb)',
    '--text-color': theme.palette.mode === 'dark' ? 'var(--color-dark)' : 'var(--color-light)',
    '--interactive': theme.palette.mode === 'dark' ? 'var(--interactive-dark)' : 'var(--interactive-light)',
    '--interactive-rgb': theme.palette.mode === 'dark' ? 'var(--interactive-dark-rgb)' : 'var(--interactive-light-rgb)',
    '--visited': theme.palette.mode === 'dark' ? 'var(--visited-dark)' : 'var(--visited-light)',
    '--hover': theme.palette.mode === 'dark' ? 'var(--hover-dark)' : 'var(--hover-light)',
    '--pressed': theme.palette.mode === 'dark' ? 'var(--pressed-dark)' : 'var(--pressed-light)',
    '--focus-color': theme.palette.mode === 'dark' ? 'var(--focus-color-dark)' : 'var(--focus-color-light)',
    '--focus': theme.palette.mode === 'dark' ? 'var(--focus-color-dark)' : 'var(--focus-color-light)',
    color: 'var(--color)',
    position: 'relative',
    'label + &': {
      marginTop: 'calc(var(--input-size) * 0.25 - var(--spacing-scale-half))'
    },
    [`& .${autocompleteClasses.input}`]: {
      color: 'var(--color-light)',
      display: 'block',
      fontSize: 'var(--font-size-scale-up-01)',
      fontWeight: 'var(--font-weight-medium)',
      height: 'calc(var(--input-size) * .9)',
      width: '100%',
      border: 'none!important',
      paddingBottom: '0',
      paddingLeft: 'var(--spacing-scale-base)',
      paddingRight: 'var(--spacing-scale-base)',
      paddingTop: '0',
    },
    [`& .input-container`]: {
      backgroundColor: theme.palette.mode === 'dark' ? 'var(--pure-0)' : 'var(--background-light)',
      borderColor: 'var(--border-color-alternative)',
      borderRadius: 'var(--surface-rounder-sm)',
      borderStyle: 'var(--border-style)',
      borderWidth: 'var(--border-width)',
      color: 'var(--color-light)',
      display: 'block',
      fontSize: 'var(--font-size-scale-up-01)',
      fontWeight: 'var(--font-weight-medium)',
      height: 'var(--input-size)',
      width: '100%',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? 'var(--pure-0)' : 'var(--gray-2)',
      }
    },
    [`&.${autocompleteClasses.error} input`]: {
      border: 'none'
    },
    [`&.${autocompleteClasses.error} .input-container`]: {
      borderColor: 'var(--danger)'
    },
    [`&.${autocompleteClasses.focused} input`]: {
      border: 'none',
      outline: 'none',
    },
    [`&.${autocompleteClasses.focused} .input-container`]: {
      borderColor: 'var(--focus) !important',
      boxShadow: '0 0 0 var(--surface-width-md) var(--focus)',
      outline: 'none',
    },
    [`&.${autocompleteClasses.expanded} svg`]: {
      transform: 'rotate(180deg)'
    },
    [`$ .${autocompleteClasses.option} .${autocompleteClasses.focused}`]: {
      color: 'var(--interactive-light)',
      backgroundColor: 'var(--pure-100)'
    }
})})

const GVAutocomplete = React.memo(function GVAutocomplete(props) {
  const methods = useFormContext();
  const { control, formState: { errors }, clearErrors } = methods
  const formValues = methods.watch()
  const handleChange = (e, value) => {
    props.changeSelect(e, value);
  }

  return (
    <FormControl error={Boolean(errors && errors[props.name])} fullWidth={props.fullWidth} variant="standard">
      <GVBRAutocompleteLabel id={`${props.label}-select`} style={props.error ? { color: 'var(--danger)' } : {}}>
        {props.StartIcon && <props.StartIcon style={{ fontSize: 20, marginRight: 5 }} />}
        {props.label}
      </GVBRAutocompleteLabel>
      <Controller
        name={props.name}
        control={control}
        render={({ field }) => (
          props.value ?
          <GVBRAutocomplete

            {...field}
            value={props.value?.id && props.value?.id != 0 ? props.value : null}
            aria-labelledby={`${props.label}-select`}
            id={`${props.label}-select`}
            options={props.options}
            getOptionLabel={(option) => option.name}
            placeholder={props.placeholder}
            disabled={props.disabled}
            forcePopupIcon
            popupIcon={<ExpandMoreIcon />}
            onChange={(e, value) => {
              field.onChange(value)
              handleChange(e,value)
            }}
            renderInput={(params) => (
              <div className='input-container'
                style={{
                  ...(errors[props.name] ? { borderColor: 'var(--danger)' } : {}),
                  ...(props.disabled ? { background: 'rgb(200, 200, 200)' } : {})
                }} 
                ref={params.InputProps.ref}
              >
                <input
                  style={{ 
                    zIndex: 2, position: 'relative', background: 'transparent', 
                    ...(props.disabled ? { color: '#999999'} : {})
                  }}
                  type="text"
                  placeholder={props.placeholder}
                  {...params.inputProps} />
                <InputAdornment position="end"
                sx={{ position: 'absolute', top: '50%', right: '1%', bottom: 0, zIndex: '1' }}>
                    <ExpandMoreIcon sx={{ color: 'var(--interactive)' }} />
                </InputAdornment>
              </div>
            )}
          /> : <GVBRAutocomplete
            {...field}
            aria-labelledby={`${props.label}-select`}
            id={`${props.label}-select`}
            options={props.options}
            getOptionLabel={(option) => option.name}
            placeholder={props.placeholder}
            disabled={props.disabled}
            forcePopupIcon
            popupIcon={<ExpandMoreIcon />}
            onChange={(e, value) => {
              field.onChange(value)
              handleChange(e,value)
            }}
            renderInput={(params) => (
              <div className='input-container'
                style={{
                  ...(errors[props.name] ? { borderColor: 'var(--danger)' } : {}),
                  ...(props.disabled ? { background: 'rgb(200, 200, 200)' } : {})
                }} 
                ref={params.InputProps.ref}
              >
                <input
                  style={{ 
                    zIndex: 2, position: 'relative', background: 'transparent', 
                    ...(props.disabled ? { color: '#999999'} : {})
                  }}
                  type="text"
                  placeholder={props.placeholder}
                  {...params.inputProps} />
                <InputAdornment position="end"
                sx={{ position: 'absolute', top: '50%', right: '1%', bottom: 0, zIndex: '1' }}>
                    <ExpandMoreIcon sx={{ color: 'var(--interactive)' }} />
                </InputAdornment>
              </div>
            )}
          />
        )} />
      {props.required && errors && errors[props.name] && (
          <GVFeedback danger>{errors[props.name].message}</GVFeedback>
      )}
    </FormControl>
  );
})

export default GVAutocomplete